<template>
  <div>
    <div class="d-flex flex-column flex-root">
      <!--begin::Page-->
      <div class="page d-flex flex-row flex-column-fluid">
        <Asidemenu></Asidemenu>
        <!--begin::Wrapper-->
        <div class="wrapper d-flex flex-column flex-row-fluid" id="kt_wrapper">
          <Header></Header>
          <!--begin::Content-->
          <div class="content d-flex flex-column flex-column-fluid" id="kt_content">
            <!--begin::Post-->
            <div class="post d-flex flex-column-fluid" id="kt_post">
              <!--begin::Container-->
              <div id="kt_content_container" class="container-xxl">
                <!--begin::Navbar-->
                <div class="card mb-5 mb-xl-10">
                  <div class="card-body pt-9 pb-0">
                    <!--begin::Details-->
                    <div class="d-flex flex-wrap flex-sm-nowrap mb-3">
                      <!--begin::Info-->
                      <div class="flex-grow-1">
                        <!--begin::Stats-->
                        <div class="d-flex flex-wrap flex-stack">
                          <!--begin::Wrapper-->
                          <div class="d-flex flex-column flex-grow-1 pe-8">
                            <!--begin::Stats-->
                            <div class="d-flex flex-wrap">
                              <!--begin::Stat-->
                              <div class="
                                  border border-gray-300 border-dashed
                                  rounded
                                  min-w-125px
                                  py-3
                                  px-4
                                  me-4
                                  mb-3
                                ">
                                <!--begin::Number-->
                                <div class="d-flex align-items-center">
                                  <!--begin::Svg Icon | path: icons/duotune/arrows/arr066.svg-->
                                  <span class="svg-icon svg-icon-3 svg-icon-success">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
                                      fill="none">
                                      <rect opacity="0.5" x="13" y="6" width="13" height="2" rx="1"
                                        transform="rotate(90 13 6)" fill="black" />
                                      <path
                                        d="M12.5657 8.56569L16.75 12.75C17.1642 13.1642 17.8358 13.1642 18.25 12.75C18.6642 12.3358 18.6642 11.6642 18.25 11.25L12.7071 5.70711C12.3166 5.31658 11.6834 5.31658 11.2929 5.70711L5.75 11.25C5.33579 11.6642 5.33579 12.3358 5.75 12.75C6.16421 13.1642 6.83579 13.1642 7.25 12.75L11.4343 8.56569C11.7467 8.25327 12.2533 8.25327 12.5657 8.56569Z"
                                        fill="black" />
                                    </svg>
                                  </span>
                                  <!--end::Svg Icon-->
                                  <div class="fs-2 fw-bolder w-140px" data-kt-countup="true"
                                    :data-kt-countup-value="supbalance" data-kt-countup-prefix="$"
                                    data-kt-countup-decimal-places="2">
                                    0
                                  </div>
                                </div>
                                <!--end::Number-->
                                <!--begin::Label-->
                                <div class="fw-bold fs-6 text-gray-400">
                                  {{ this.$t('dashboard.supplybalance')}}
                                </div>
                                <!--end::Label-->
                              </div>
                              <div class="
                                  border border-gray-300 border-dashed
                                  rounded
                                  min-w-125px
                                  py-3
                                  px-4
                                  me-4
                                  mb-3
                                ">
                                <!--begin::Number-->
                                <div class="d-flex align-items-center">
                                  <!--begin::Svg Icon | path: icons/duotune/arrows/arr066.svg-->
                                  <span class="svg-icon svg-icon-3 svg-icon-success">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
                                      fill="none">
                                      <rect opacity="0.5" x="13" y="6" width="13" height="2" rx="1"
                                        transform="rotate(90 13 6)" fill="black" />
                                      <path
                                        d="M12.5657 8.56569L16.75 12.75C17.1642 13.1642 17.8358 13.1642 18.25 12.75C18.6642 12.3358 18.6642 11.6642 18.25 11.25L12.7071 5.70711C12.3166 5.31658 11.6834 5.31658 11.2929 5.70711L5.75 11.25C5.33579 11.6642 5.33579 12.3358 5.75 12.75C6.16421 13.1642 6.83579 13.1642 7.25 12.75L11.4343 8.56569C11.7467 8.25327 12.2533 8.25327 12.5657 8.56569Z"
                                        fill="black" />
                                    </svg>
                                  </span>
                                  <!--end::Svg Icon-->
                                  <div class="fs-2 fw-bolder w-140px" data-kt-countup="true"
                                    :data-kt-countup-value="`${borrowbalanceUSDT}`" data-kt-countup-prefix="$"
                                    data-kt-countup-decimal-places="2">
                                    0
                                  </div>
                                </div>
                                <!--end::Number-->
                                <!--begin::Label-->
                                <div class="fw-bold fs-6 text-gray-400">
                                  {{$t("dashboard.borrowbalance")}}
                                </div>
                                <!--end::Label-->
                              </div>
                              <!--end::Stat-->
                            </div>
                            <!--end::Stats-->
                          </div>
                          <!--end::Wrapper-->
                          <!--begin::Progress-->
                          <div class="
                              d-flex
                              align-items-center
                              w-200px w-sm-300px
                              flex-column
                              mt-3
                            ">
                            <div class="
                                d-flex
                                justify-content-between
                                w-100
                                mt-auto
                                mb-2
                              ">
                              <span class="fw-bold fs-6 text-gray-400">{{this.$t('dashboard.availablecredit')}}</span>
                              <span class="fw-bolder fs-6 fw-bolder" data-kt-countup="true"
                                :data-kt-countup-value="borrowlimit" data-kt-countup-prefix="$"
                                data-kt-countup-decimal-places="2">0</span>
                            </div>
                            <div class="
                                d-flex
                                justify-content-between
                                w-100
                                mt-auto
                                mb-2
                              ">
                              <span class="fw-bold fs-6 text-gray-400">{{$t("dashboard.borrowlimit")}}</span>
                              <span class="fw-bolder fs-6">{{ borrowbalanceyuzde.toFixed(2) }}%</span>
                            </div>
                            <div class="h-5px mx-3 w-100 bg-light mb-3">
                              <div :class="`bg-${isSuccessBorrowUst} rounded h-5px`" role="progressbar"
                                :style="`width: ${borrowbalanceyuzde}%;`" aria-valuenow="50" aria-valuemin="0"
                                aria-valuemax="100"></div>
                            </div>
                          </div>
                          <!--end::Progress-->
                        </div>
                        <!--end::Stats-->
                      </div>
                      <!--end::Info-->
                    </div>
                    <!--end::Details-->
                  </div>
                </div>
                <!--end::Navbar-->
                <!--begin::Row-->
                <div class="row gy-5 g-xl-8">
                  <!--depositwithdraw tablo start-->
                  <div class="col-xl-12">
                    <!--begin::Tables Widget 5-->
                    <div class="card card-xl-stretch mb-5 mb-xl-10">
                      <!--begin::Header-->
                      <div class="card-header border-0 pt-5">
                        <div class="card-toolbar">
                          <ul class="nav">
                            <li class="nav-item">
                              <a class="
                                  nav-link
                                  btn
                                  btn-sm
                                  btn-color-muted
                                  btn-active
                                  btn-active-dark
                                  active
                                  fw-bolder
                                  px-4
                                  me-1
                                " data-bs-toggle="tab" href="#kt_table_widget_5_tab_1_sag">{{$t("deposit.swaphistory")}}</a>
                            </li>
                          </ul>
                        </div>
                        <div class="mb-0 float-end">
                          <Datepicker v-model="historyDate1" class="float-start ms-2"
                            :input-class="'btn btn-sm bg-dark text-light mt-3'" :format="'yyyy/MM/dd'"
                            placeholder="Lütfen bir tarih aralığı seçiniz." :language="dil[this.$i18n.locale][0]"
                            @input="swapHistoryData()"></Datepicker>
                          <Datepicker v-model="historyDate2" class="float-start ms-2 sagtarih"
                            :input-class="'btn btn-sm bg-dark text-light mt-3'" :format="'yyyy/MM/dd'"
                            placeholder="Lütfen bir tarih aralığı seçiniz." :language="dil[this.$i18n.locale][0]"
                            @input="swapHistoryData()"></Datepicker>
                        </div>
                      </div>
                      <!--end::Header-->
                      <!--begin::Body-->
                      <div class="card-body py-3">
                        <div class="tab-content">
                          <!--begin::Tap pane-->
                          <div class="tab-pane fade show active" id="kt_table_widget_5_tab_1_sag">
                            <!--begin::Table container-->
                            <div class="table-responsive">
                              <!--begin::Table-->
                              <table class="
                                  table table-row-dashed table-row-gray-200
                                  align-middle
                                  gs-0
                                  gy-4
                                ">
                                <!--begin::Table head-->
                                <thead>
                                  <tr class="border-0">
                                    <th>{{$t("deposit.swapid")}}</th>
                                    <th>From</th>
                                    <th>To</th>
                                    <th>{{$t("deposit.fromamount")}}</th>
                                    <th>{{$t("deposit.toamount")}}</th>
                                    <th>{{$t("deposit.date")}}</th>
                                  </tr>
                                </thead>
                                <!--end::Table head-->
                                <!--begin::Table body-->
                                <tbody>
                                  <tr v-for="(swapData, index) in swapHistory" :key="index">
                                    <td>
                                      <a href="#" data-bs-toggle="modal" data-bs-target="#kt_modal_create_depWith"
                                        class="
                                          text-dark
                                          fw-bolder
                                          text-hover-primary
                                          mb-1
                                          fs-6
                                        ">#{{
                                        swapData.exchange_id
                                        }}</a>
                                    </td>
                                    <td>
                                      <a href="#" data-bs-toggle="modal" data-bs-target="#kt_modal_create_depWith"
                                        class="
                                          text-dark
                                          fw-bolder
                                          text-hover-primary
                                          mb-1
                                          fs-6
                                        ">{{
                                        swapData.exchange_from_wallet
                                        }}</a>
                                      <span class="text-muted fw-bold d-block">
                                        <a href="#" data-bs-toggle="modal" data-bs-target="#kt_modal_create_depWith"
                                          class="
                                            text-muted
                                            fw-bolder
                                            text-hover-primary
                                          ">{{ (walletList[swapData.exchange_from_wallet]) ?
                                          walletList[swapData.exchange_from_wallet]["wallet_name"] : ""}}</a>
                                      </span>
                                    </td>
                                    <td>
                                      <a href="#" data-bs-toggle="modal" data-bs-target="#kt_modal_create_depWith"
                                        class="
                                          text-dark
                                          fw-bolder
                                          text-hover-primary
                                          mb-1
                                          fs-6
                                        ">{{
                                        swapData.exchange_to_wallet
                                        }}</a>
                                      <span class="text-muted fw-bold d-block">
                                        <a href="#" data-bs-toggle="modal" data-bs-target="#kt_modal_create_depWith"
                                          class="
                                            text-muted
                                            fw-bolder
                                            text-hover-primary
                                          ">{{ (walletList[swapData.exchange_to_wallet]) ?
                                          walletList[swapData.exchange_to_wallet]["wallet_name"] : ""}}</a>
                                      </span>
                                    </td>
                                    <td>
                                      <span>{{ (swapData.exchange_from_amount) ? swapData.exchange_from_amount.toFixed(8) :
                                        0}}</span>
                                      <span class="text-muted fw-bold d-block">{{(walletList[swapData.exchange_from_wallet]) ? (swapData.exchange_from_amount * walletList[
                                        swapData.exchange_from_wallet]["wallet_price"]).toFixed(2) : 0}}
                                        USDT</span>
                                    </td>
                                    <td>
                                      <span>{{ (swapData.exchange_to_amount) ? swapData.exchange_to_amount.toFixed(8) :
                                        0}}</span>
                                      <span class="text-muted fw-bold d-block">{{(walletList[swapData.exchange_to_wallet]) ? (swapData.exchange_to_amount * walletList[
                                        swapData.exchange_to_wallet]["wallet_price"]).toFixed(2) : 0}}
                                        USDT</span>
                                    </td>
                                    <td>
                                      {{
                                      new Date(swapData.exchange_time * 1000).toLocaleString()
                                      }}
                                    </td>
                                  </tr>
                                </tbody>
                                <!--end::Table body-->
                              </table>
                            </div>
                            <!--end::Table-->
                          </div>
                        </div>
                      </div>
                      <!--end::Body-->
                    </div>
                    <!--end::Tables Widget 5-->
                  </div>
                  <!--depositwithdraw tablo end-->
                </div>
                <!--end::Row-->
              </div>
              <!--end::Container-->
            </div>
            <!--end::Post-->
          </div>
          <!--end::Content-->
          <Footer></Footer>
        </div>
        <!--end::Wrapper-->
      </div>
      <!--end::Page-->
    </div>
    <!--end::Root-->
    <!--begin::Drawers-->
    <!--begin::Scrolltop-->
    <div id="kt_scrolltop" class="scrolltop" data-kt-scrolltop="true">
      <!--begin::Svg Icon | path: icons/duotune/arrows/arr066.svg-->
      <span class="svg-icon">
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
          <rect opacity="0.5" x="13" y="6" width="13" height="2" rx="1" transform="rotate(90 13 6)" fill="black" />
          <path
            d="M12.5657 8.56569L16.75 12.75C17.1642 13.1642 17.8358 13.1642 18.25 12.75C18.6642 12.3358 18.6642 11.6642 18.25 11.25L12.7071 5.70711C12.3166 5.31658 11.6834 5.31658 11.2929 5.70711L5.75 11.25C5.33579 11.6642 5.33579 12.3358 5.75 12.75C6.16421 13.1642 6.83579 13.1642 7.25 12.75L11.4343 8.56569C11.7467 8.25327 12.2533 8.25327 12.5657 8.56569Z"
            fill="black" />
        </svg>
      </span>
      <!--end::Svg Icon-->
    </div>
    <!--end::Scrolltop-->
    <!--end::Main-->
  </div>
</template>
<script>
  import DataService from "../services/DataService";
  import Asidemenu from "./Sidebar";
  import Header from "./shares/Header";
  import Footer from "./shares/Footer";
  import Datepicker from "vuejs-datepicker";
  import { tr, en, ru } from "vuejs-datepicker/dist/locale"; //this.$i18n.locale
  export default {
    name: "SwapHistory",
    metaInfo: function() {
      return {
        title: this.$t("metatag.titledefault"),
        titleTemplate: "%s | "+this.$t("metatag.titleDetailSwap"),
        meta: [
        { name: 'description', content:  this.$t("metatag.description") },
        { name: 'keywords', content:  this.$t("metatag.keywords") }
        ]
      }
    },
    components: {
      Asidemenu,
      Header,
      Footer,
      Datepicker,
    },
    data() {
      return {
        walletall: {},
        dil: { tr: [tr], en: [en], ru: [ru] },
        selectWalletShort: "",
        historyDate1: new Date(Date.now() - 3600 * 1000 * 24 * 15),
        historyDate2: new Date(),
        walletList: {},
        supbalance: 0,
        borrowlimit: 0,
        isSuccess: {},
        isSuccessb: {},
        borrowbalanceUSDT: 0,
        userControl: this.$session.get("logtoken") ? 1 : 0,
        totalSupply: {},
        totalBorrow: {},
        swapHistory: {},
      };
    },
    methods: {
      sessionKontrol() {
        if (!this.$session.get("logtoken")) {
          window.location.href = `/${this.$session.get("lang")}/login`;
        }
      },
      allWallets() {
        if (this.userControl != 1) return;
        if (this.sayfaDurum == 0) { return; }
        this.supbalance = 0;
        this.borrowlimit = 0;
        this.borrowbalanceUSDT = 0;
        DataService.getUserWallets({
          token: this.$session.get("logtoken"),
        }).then((response2) => {
          DataService.getAllWallets().then((response) => {
            this.walletall = response.data;
            for (let index = 0; index < response.data.length; index++) {
              const element = response.data[index];
              if (element) this.walletList[element.wallet_short] = element;
              this.userWallet(element.wallet_short, response2.data[element.wallet_short]);
            }
          });
        });
      },
      userWallet(gelen, userWallet) {
        if (userWallet) {
          if (userWallet.wallet_user_earn === 1) {
            this.supbalance = this.supbalance + userWallet.wallet_user_balance * this.walletList[gelen]["wallet_price"];
          }
          if (userWallet.wallet_user_coll === 1) {
            this.borrowlimit = this.borrowlimit + userWallet.wallet_user_balance *
              this.walletList[gelen]["wallet_price"] * ("0." + this.walletList[gelen].wallet_col_factor);
          }



          if (userWallet.wallet_user_borrow_balance) {
            this.walletList[gelen]["borrowbalance"] = userWallet.wallet_user_borrow_balance;
            this.$session.set(gelen + '_bbalance', this.walletList[gelen]["borrowbalance"])
            this.borrowbalanceUSDT = this.borrowbalanceUSDT + userWallet.wallet_user_borrow_balance * this.walletList[gelen]["wallet_price"];
          } else {
            this.$session.set(gelen + '_bbalance', 0)
          }

        }
      },
      veriYenile() {
        setInterval(() => {
          this.allWallets();
        }, 15000);
      },
      swapHistoryData() {
        if (this.historyDate1 && this.historyDate2) {
          DataService.swapHistory({
            token: this.$session.get("logtoken"),
            date1: this.historyDate1.getTime().toString().substring(0, 10),
            date2: this.historyDate2.getTime().toString().substring(0, 10),
          }).then((response) => {
            this.swapHistory = response.data;
            this.$forceUpdate();
          });
        }
      },
      copyInput(input, veri) {
        navigator.clipboard.writeText(input);
        this.isCopied = true;
        this.$vToastify.setSettings({ position: "bottom-right", theme: "light" });
        this.$vToastify.success("", veri + " başarıyla kopyalandı.");
      },
    },
    computed: {
      borrowbalanceyuzde: function () {
        let kontrol = "";
        if ((this.borrowbalanceUSDT / this.borrowlimit) * 100) {
          kontrol = (this.borrowbalanceUSDT / this.borrowlimit) * 100;
        } else {
          kontrol = 0;
        }
        return kontrol;
      },
      isSuccessBorrowUst: function () {
        let kontrol = "";
        if (this.borrowbalanceyuzde <= 50) {
          kontrol = "success";
        } else if (this.borrowbalanceyuzde > 50 && this.borrowbalanceyuzde < 80) {
          kontrol = "warning";
        } else if (this.borrowbalanceyuzde > 80) {
          kontrol = "danger";
        }
        return kontrol;
      },
    },
    mounted() {
      this.sessionKontrol();
      const d = new Date();let time = d.getTime().toString().substring(0, 10);this.$session.set("sessionTimeout",parseInt(time)+3600);
    },
    created() {
      this.allWallets();
      this.swapHistoryData();
    },
  };
</script>