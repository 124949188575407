<template>
  <div>
    <SwapHistory></SwapHistory>
  </div>
</template>

<script>
// @ is an alias to /src
import SwapHistory from '@/components/SwapHistory.vue'

export default {
  name: 'SwapHistoryView',
  components: {
    SwapHistory,
  }
}
</script>
